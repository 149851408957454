import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './App.scss';

import {  Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(0),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);


const App: React.FC<any> = (props) => {
  const classes = useStyles();

  useEffect(() => {
      
      return() => {
      }
  }, []);


  const playBuddyGame = () => {
    window.open('https://buddygame.gameofwins.com', '_self' );
  }
  const playVotingGame = () => {
    //window.open('https://buddygame.gameofwins.com', '_self' );
  }


  return (
    <div className="App">
      <div className={classes.root}>
        <h1>Group, Family & Multiplayer Games</h1>

        <div className="grid">
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}  >
            <Grid item xs={12} className="image-container">
              <img src='./buddyGame.jpg' style={{"maxWidth": "100%"}} alt="The Buddy Game" />
              <button  onClick={playBuddyGame}>The Buddy Game</button>
            </Grid>
            <Grid item xs={12} className="center">
              <div className="intro">
                <p>See who knows you best with this classic variation on the Buddy game!</p>
                <p>You have 15 seconds to select the most likely answer to a delving and in some cases personal question. At the same time, your friends have to guess how you will chose.</p>
                <p>Honesty gets you most points!</p>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="grid">
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}  >
            <Grid item xs={12} className="image-container">
              <img src='./buddyGame.jpg' style={{"maxWidth": "100%"}} alt="The Buddy Game" />
              <button  onClick={playVotingGame}>The Voting Game</button>
            </Grid>
            <Grid item xs={12} className="center">
              <div className="intro">
                <p>Coming soon...</p>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    </div>
  )
}

export default App;
